<template>
  <div>
    <a-modal
      :visible="visibleModalManageAbsensi"
      :title="tipeDetailRekapKehadiran === 'edit' ? `Edit Detil Kehadiran ${dataPegawai.namaPegawai}` : 'Form Tambah Kehadiran Baru'"
      centered
      closable
      @cancel="toggleModalManageAbsensi('cancel')"
      :footer="null"
    >
      <!-- <template slot="footer">
        <a-button key="cancel" @click.prevent="clickShowDetail">Batal</a-button>
        <a-button key="ok" type="primary" @click.prevent="handleOkManageAbsensi">Ubah</a-button>
      </template> -->
      <DetilLogKehadiran :tipeDetailRekapKehadiran="tipeDetailRekapKehadiran" :data="detilLog" :logDate="detilLog.tanggal || null" :isDetailRekapKehadiran="isDetailRekapKehadiran" @on-change-detil-log="handleOkManageAbsensi" @on-cancel-detil-log="toggleModalManageAbsensi('cancel')"/>
    </a-modal>
    <!-- <a-modal
      v-model="visibleModalManageAbsensi"
      title="Form Tambah Absensi"
      @ok="handleOkManageAbsensi"
      okText="Tambah"
      cancelText="Kembali"
      @cancel="toggleModalManageAbsensi('cancel')"
      centered
    >
      <a-col :span="24">
        <a-form-item label="Tanggal">
          <a-date-picker v-model="selectDate" size="large" @change="(moment, val) => handleDate('manage', moment, val)"/>
        </a-form-item>
      </a-col>
      <a-col :span="12">
        <a-form-item label="Jam Masuk">
          <a-time-picker @change="(moment, time) => handleTime('in', moment, time)"/>
        </a-form-item>
      </a-col>
      <a-col :span="12">
        <a-form-item label="Jam Keluar">
          <a-time-picker @change="(moment, time) => handleTime('out', moment, time)"/>
        </a-form-item>
      </a-col>
    </a-modal> -->
    <h1>Detail Kehadiran Harian - Pegawai</h1>
    <a-row>
      <a-col :span="24">
        <div class="card-full">
          <div class="detail">
            <div class="tableDailyAttendance">
              <div class="cui__utils__heading mb-3">
                <strong>Rules</strong>
              </div>
              <div class="text-muted mb-3">
                Pada halaman ini, anda dapat: menambah, melihat, merubah, menghapus absensi pegawai.
              </div>
              <a-collapse>
                <a-collapse-panel header="Info">
                  <ul>
                    <li>
                      Jika absensi pada periode ini telah terfinalisasi, maka anda hanya dapat melihat absensi pegawai.
                    </li><br>
                    <li>
                      Jika terdapat perubahan absensi menjadi izin / cuti, adminHR perlu menghapus absensi pegawai pada hari tersebut. <br> Kemudian meminta pegawai untuk melakukan pengajuan izin / cuti.
                    </li>
                  </ul>
                </a-collapse-panel>
              </a-collapse>
              <hr/>
                <a-descriptions title="Detail Pegawai & Periode Absensi" bordered size="middle">
                  <a-descriptions-item label="Nama">
                    {{ dataPegawai.namaPegawai }}
                  </a-descriptions-item>
                  <a-descriptions-item label="NIR">
                    {{ dataPegawai.nirPegawai }}
                  </a-descriptions-item>
                  <a-descriptions-item label="Unit">
                    {{ dataPegawai.unitPegawai }}
                  </a-descriptions-item>
                  <a-descriptions-item label="Jabatan">
                    {{ dataPegawai.jabatanPegawai }}
                  </a-descriptions-item>
                  <a-descriptions-item label="Periode">
                    {{ periodStartDate+'-'+periodEndDate+' '+dataPeriode.bulan+', '+dataPeriode.tahun }}
                  </a-descriptions-item>
                </a-descriptions>
                <a-collapse>
                  <a-collapse-panel header="Jadwal Shift Pegawai">
                    <a-table
                      :loading="loading.table"
                      :columns="shiftScheduleColumns"
                      :data-source="dataJadwalShift"
                      bordered
                    >
                      <span slot="shiftTolerance" slot-scope="text">
                        {{ text+' Menit'}}
                      </span>
                    </a-table>
                  </a-collapse-panel>
                </a-collapse>
              <hr/>
              <div :class="['d-flex', 'flex-nowrap']">
                <div>
                  <a-date-picker placeholder="Filter Table by Tanggal" size="large" @change="(moment, val) => handleDate('fetch', moment, val)"/>
                </div>
                <div class="ml-auto mb-4">
                  <a-button
                    :disabled="disableAbsensi"
                    :loading="loading.add"
                    type="primary"
                    icon="plus"
                    style="height: 40px"
                    @click.prevent="toggleModalManageAbsensi('add')"
                    >Tambah</a-button
                  >
                </div>
              </div>
              <div>
              </div>
              <a-table
                :loading="loading.table"
                :columns="dailyAttendanceColumns"
                :data-source="dataTable"
                bordered
              >
                <span slot="tanggal" slot-scope="text">
                  {{ moment(text).locale('id').format('dddd, DD MMMM YYYY') }}
                </span>
                <span slot="terlambat" slot-scope="text">
                  {{ text ? `${moment.duration(text, 'minutes').hours()} Jam, ${moment.duration(text, 'minutes').minutes()} Menit` : '-' }}
                </span>
                <span slot="aksi" slot-scope="value, record">
                  <a-button
                    :disabled="disableAbsensi"
                    :loading="loading.edit"
                    class="btn-outline-success mr-3"
                    icon="edit"
                    size="large"
                    @click.prevent="toggleModalManageAbsensi('edit', record)"
                  >Ubah</a-button>
                  <a-button
                    :disabled="disableAbsensi"
                    :loading="loading.delete"
                    class="btn-outline-danger"
                    icon="delete"
                    size="large"
                    @click.prevent="handleDeleteAbsensi(record)"
                  >Hapus</a-button>
                </span>
              </a-table>
            </div>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import moment from 'moment'
const DetilLogKehadiran = () => import('@/components/app/DetilLogKehadiran')

const dailyAttendanceColumns = [
  {
    title: 'Tanggal',
    dataIndex: 'tanggal',
    key: 'tanggal',
    scopedSlots: { customRender: 'tanggal' },
    align: 'center',
  },
  {
    title: 'Dibuat Oleh',
    dataIndex: 'createdBy',
    key: 'createdBy',
    align: 'center',
    scopedSlots: { customRender: 'createdBy' },
  },
  {
    title: 'Terlambat',
    dataIndex: 'terlambat',
    key: 'terlambat',
    align: 'center',
    scopedSlots: { customRender: 'terlambat' },
  },
  {
    title: 'Aksi',
    dataIndex: 'aksi',
    key: 'aksi',
    align: 'center',
    scopedSlots: { customRender: 'aksi' },
  },
]

const shiftScheduleColumns = [
  {
    title: 'Hari',
    dataIndex: 'shiftDay',
    key: 'shiftDay',
    scopedSlots: { customRender: 'shiftDay' },
    align: 'center',
  },
  {
    title: 'Jam Masuk',
    dataIndex: 'shiftTimeIn',
    key: 'shiftTimeIn',
    scopedSlots: { customRender: 'shiftTimeIn' },
    align: 'center',
  },
  {
    title: 'Jam Keluar',
    dataIndex: 'shiftTimeOut',
    key: 'shiftTimeOut',
    scopedSlots: { customRender: 'shiftTimeOut' },
    align: 'center',
  },
  {
    title: 'Batas Toleransi',
    dataIndex: 'shiftTolerance',
    key: 'shiftTolerance',
    scopedSlots: { customRender: 'shiftTolerance' },
    align: 'center',
  },
]

export default {
  data() {
    return {
      moment,
      dailyAttendanceColumns,
      shiftScheduleColumns,
      dataTable: [],
      pagination: {},
      dataPegawai: {},
      dataPeriode: {},
      dataShift: {},
      dataJadwalShift: [],
      search: null,
      selectDate: null,
      // selectInTime: null,
      // selectOutTime: null,
      periodStartDate: null,
      periodEndDate: null,
      loading: {
        table: false,
        add: false,
        edit: false,
        delete: false,
      },
      visibleModalManageAbsensi: false,
      status: {
        isCreate: false,
      },
      isDetailRekapKehadiran: true,
      tipeDetailRekapKehadiran: null,
      loadingModalManageAbsensi: false,
      disableAbsensi: false,
      detilLog: {
        tanggal: null,
        jam_masuk: null,
        jam_keluar: null,
      },
      showDetail: false,
    }
  },
  components: {
    DetilLogKehadiran,
  },
  methods: {
    fetchDataPeriodePegawai() {
      const idPegawai = this.$route.params.idPegawai
      const idPeriode = this.$route.params.idPeriode
      this.$store
        .dispatch('absensiKaryawan/FETCH_PERIODE_PEGAWAI', {
          idPegawai,
          idPeriode,
        })
        .then((res) => {
          this.dataPegawai = res.dataPegawai
          this.dataPeriode = res.dataPeriode
          if (res.dataPeriode.status_periode === 'terfinalisasi') {
            this.disableAbsensi = true
          } else {
            this.disableAbsensi = false
          }
          const tanggalAwal = res.dataPeriode.tanggal_awal
          this.periodStartDate = moment(tanggalAwal).date()
          const tanggalAkhir = res.dataPeriode.tanggal_akhir
          this.periodEndDate = moment(tanggalAkhir).date()
        })
    },
    fetchDataAbsensi() {
      const idPegawai = this.$route.params.idPegawai
      const idPeriode = this.$route.params.idPeriode
      this.$store
        .dispatch('absensiKaryawan/FETCH_DAILY_ATTENDANCE', {
          idPegawai,
          idPeriode,
          selectDate: this.selectDate,
        })
        .then((res) => {
          this.dataShift = res.dataShift
          const jadwalShift = res.dataShift.shift.jadwal_shifts
          if (jadwalShift.length) {
            this.dataJadwalShift = jadwalShift.map(rowShift => {
              return {
                key: rowShift.id,
                shiftDay: rowShift.hari,
                shiftTimeIn: rowShift.jam_masuk,
                shiftTimeOut: rowShift.jam_keluar,
                shiftTolerance: rowShift.toleransi,
              }
            })
          }
          this.dataTable = res.dataAbsensi
          this.selectDate = null
        })
    },
    clearAllData () {
      const newData = {}
      newData.tanggal = null
      const arrSelectView = ['masuk', 'keluar']
      for (const i in arrSelectView) {
        newData['jam_' + arrSelectView[i]] = null
        newData['browser_' + arrSelectView[i]] = null
        newData['os_' + arrSelectView[i]] = null
        newData['device_' + arrSelectView[i]] = null
        newData['lokasi_' + arrSelectView[i]] = null
        newData['ip_' + arrSelectView[i]] = null
        newData['foto_' + arrSelectView[i]] = null
      }
      return newData
    },
    toggleModalManageAbsensi(tipe, data) {
      this.visibleModalManageAbsensi = !this.visibleModalManageAbsensi
      this.tipeDetailRekapKehadiran = tipe
      if (tipe === 'add' || tipe === 'cancel') {
        this.detilLog = this.clearAllData()
      } else if (tipe === 'edit') {
        const newRecord = {
          browser_keluar: data.browserKeluar,
          browser_masuk: data.browserMasuk,
          createdBy: data.createdBy,
          device_keluar: data.deviceKeluar,
          device_masuk: data.deviceMasuk,
          foto_keluar: data.fotoKeluar,
          foto_masuk: data.fotoMasuk,
          id: data.key,
          id_absen: data.key,
          id_pegawai: this.dataPegawai.key,
          ip_keluar: data.ipKeluar,
          ip_masuk: data.ipMasuk,
          jam_keluar: moment(data.jamKeluar, 'HH:mm:ss'),
          jam_masuk: moment(data.jamMasuk, 'HH:mm:ss'),
          key: this.dataPegawai.key,
          lokasi_keluar: data.lokasiKeluar,
          lokasi_masuk: data.lokasiMasuk,
          name: this.dataPegawai.namaPegawai,
          os_keluar: data.osKeluar,
          os_masuk: data.osMasuk,
          tanggal: moment(data.tanggal, 'YYYY-MM-DD'),
          terlambat: data.terlambat,
          unit: this.dataPegawai.unitPegawai,
        }
        this.detilLog = {
          ...newRecord,
        }
      } else {
        this.detilLog = this.clearAllData()
      }
    },
    handleDate(col, moment, dateString) {
      this.selectDate = dateString
      if (col === 'fetch') {
        this.fetchDataAbsensi()
      }
    },
    // handleTime(col, moment, time) {
    //   if (col === 'in') {
    //     this.selectInTime = time
    //   } else if (col === 'out') {
    //     this.selectOutTime = time
    //   }
    // },
    handleOkManageAbsensi(payload) {
      console.log('handleOkManageAbsensi payload :>> ', payload)
      const tipeAbsensi = payload.status
      const data = payload.newData
      const idPegawai = this.$route.params.idPegawai
      this.toggleModalManageAbsensi()
      if (tipeAbsensi === 'edit') {
        const id = data.id
        this.loading.table = true
        this.loading.edit = true
        this.$store
          .dispatch('absensiKaryawan/UPDATE_ABSENSI', { id, data })
          .then(res => {
            this.detilLog = {}
            this.loading.table = false
            this.loading.edit = false
            if (res.isSuccess) {
              this.$notification.success({
                message: 'Sukses',
                description: 'Berhasil mengubah absensi pegawai',
              })
              this.fetchDataAbsensi()
            } else {
              this.$notification.error({
                message: 'Error',
                description: 'Gagal mengubah absensi pegawai',
              })
            }
          })
      } else {
        // if (this.selectDate === null || this.selectInTime === null || this.selectOutTime === null) {
        //   this.$notification.error({
        //     message: 'Error',
        //     description: 'Semua data harus terisi!',
        //   })
        // }
        if (data.tanggal === null) {
          this.$notification.error({
            message: 'Error',
            description: 'Tanggal absensi harus terisi!',
          })
        } else {
          const createData = {
            ...data,
            id_pegawai: idPegawai,
            createdBy: 'adminHR',
          }
          this.loading.table = true
          this.loading.add = true
          this.$store
            .dispatch('absensiKaryawan/POST_ABSENSI_FROM_ADMIN_HR', { data: createData })
            .then(res => {
              this.loading.table = false
              this.loading.add = false
              // this.selectDate = null
              // this.selectInTime = null
              // this.selectOutTime = null
              if (res.isSuccess) {
                this.$notification.success({
                  message: 'Sukses',
                  description: 'Berhasil menambahkan absensi baru',
                })
                this.fetchDataAbsensi()
              } else {
                this.$notification.error({
                  message: 'Error',
                  description: 'Gagal menambahkan absensi baru',
                })
              }
            })
        }
      }
    },
    handleDeleteAbsensi (data) {
      this.$confirm({
        title: 'Peringatan',
        content: (
          <div>Apakah anda yakin ingin menghapus data absensi <b>{this.dataPegawai.namaPegawai}</b> pada tanggal <b>{moment(data.tanggal).locale('id').format('DD MMMM YYYY')}</b> ?<br/> Perubahan ini akan mempengaruhi data aktual absensi milik pegawai</div>
        ),
        onOk: () => {
          this.loading.table = true
          this.loading.delete = true
          this.$store.dispatch('absensiKaryawan/DELETE_ABSENSI', {
            id: data.key,
          })
            .then(isSuccess => {
              this.fetchDataAbsensi()
              this.loading.table = false
              this.loading.delete = false
              if (isSuccess) {
                this.$notification.success({
                  message: 'Berhasil',
                  description:
                    'Absensi Pegawai berhasil dihapus',
                })
              } else {
                this.$notification.error({
                  message: 'Gagal',
                  description: 'Absensi Pegawai gagal dihapus',
                })
              }
            })
        },
        onCancel: () => {
          this.loading.table = false
          this.loading.delete = false
        },
        centered: true,
        icon: 'warning',
        okType: 'danger',
        okText: 'Hapus',
        cancelText: 'Batal',
      })
    },
    // clickShowDetail(record) {
    //   this.tipeDetailRekapKehadiran = 'edit'
    //   this.showDetail = !this.showDetail
    //   if (this.showDetail) {
    //     const newRecord = {
    //       browser_keluar: record.browserKeluar,
    //       browser_masuk: record.browserMasuk,
    //       createdBy: record.createdBy,
    //       device_keluar: record.deviceKeluar,
    //       device_masuk: record.deviceMasuk,
    //       foto_keluar: record.fotoKeluar,
    //       foto_masuk: record.fotoMasuk,
    //       id: record.key,
    //       id_absen: record.key,
    //       id_pegawai: this.dataPegawai.key,
    //       ip_keluar: record.ipKeluar,
    //       ip_masuk: record.ipMasuk,
    //       jam_keluar: moment(record.jamKeluar, 'HH:mm:ss'),
    //       jam_masuk: moment(record.jamMasuk, 'HH:mm:ss'),
    //       key: this.dataPegawai.key,
    //       lokasi_keluar: record.lokasiKeluar,
    //       lokasi_masuk: record.lokasiMasuk,
    //       name: this.dataPegawai.namaPegawai,
    //       os_keluar: record.osKeluar,
    //       os_masuk: record.osMasuk,
    //       tanggal: moment(record.tanggal, 'YYYY-MM-DD'),
    //       terlambat: record.terlambat,
    //       unit: this.dataPegawai.unitPegawai,
    //     }
    //     this.detilLog = {
    //       ...newRecord,
    //     }
    //   } else {
    //     setTimeout(() => {
    //       this.detilLog = {
    //         tanggal: null,
    //       }
    //     }, 300)
    //   }
    // },
  },
  mounted() {
    this.fetchDataPeriodePegawai()
    this.fetchDataAbsensi()
  },
}
</script>

<style scoped lang = 'scss'>
.card-full {
  padding: 20px 20px;
  margin-top: 60px;
  width: 100%;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 0px 10px #0419301a;
}
.detail {
  padding: 20px;
}
.tableDailyAttendance {
  .btn.btn-outline-danger,
  .show > .btn.btn-outline-danger {
    border-color: #f56a79 !important;
    color: white !important;
    color: #f56a79 !important;
  }

  .ant-spin-nested-loading .ant-spin-container .ant-table {
    overflow-x: auto;
    table {
      @media (max-width: 769px) {
        width: auto;
      }
    }
  }

  .btn.btn-outline-danger:hover,
  .btn.btn-outline-danger:active,
  .show > .btn.btn-outline-danger:hover,
  .show > .btn.btn-outline-danger:active {
    background-color: #f56a79 !important;
    color: white !important;
    border-color: #f56a79 !important;
  }

  .btn-outline-danger:not(:disabled):not(.disabled).active,
  .btn-outline-danger:not(:disabled):not(.disabled):active,
  .show > .btn-outline-danger.dropdown-toggle {
    background-color: #f56a79 !important;
    color: white !important;
    border-color: #f56a79 !important;
  }
}
</style>
